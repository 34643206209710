<template>
    <v-container>

        <!-- Title Modul -->
        <v-card-title class="title-bar-modul">
            <v-icon>mdi-laptop</v-icon>
            Asset Inventory
        </v-card-title>

        <v-tabs
        v-model="tabs"
        >

            <v-tab
            index=0
            key="0"
            class="tab-icon"
          >
            <v-icon>mdi-devices</v-icon>
            Asset
          </v-tab>

        </v-tabs>

        <v-tabs-items
        v-model="tabs"
        >
            <v-tab-item class="container-tab-modul">

                <!-- Detail information -->
                <v-container class="pb-0">
                    <v-card>
                        <v-card-text>
                            <ul class="bullet-icon">

                                <li>
                                <v-icon small>mdi-message-badge-outline</v-icon>
                                Please notify the infra department ( <a href="mailto:melissa@scienotech.com.my"><i>melissa@scienotech.com.my</i></a> ) if any equipment, machine, or tools appear to be damaged,
                                defective, or need to repair/maintenance.
                                </li>

                                <li>
                                <v-icon small>mdi-cursor-default-click</v-icon>
                                Click at Detail column for more info on inventory asset.
                                </li>

                            </ul>
                        </v-card-text>
                    </v-card>
                </v-container>

                <!-- <v-divider></v-divider> -->

                <inventory-datatable></inventory-datatable>

            </v-tab-item>
        </v-tabs-items>

        

    </v-container>
</template>

<script>
// import axios from 'axios';
import dtInventory from '@/components/assetInventory/Datatable'
export default {
    components: {
        'inventory-datatable': dtInventory,
    },
    data: () => ({

        tabs: 0,

    }),
    methods: {

    },
    mounted(){
        
    },
    
    
}

</script>

<style lang="scss">
@import '~scss/main';



</style>
