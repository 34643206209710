<template>
    <v-container>

        <!-- Data Table -->
        <v-card class="mt-5 darkTable">
            <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="searchDtAsset"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                outlined
                dense
                class="shrink"
            ></v-text-field>
            </v-card-title>
            <v-data-table
            id="dtCarBook"
            :items-per-page="5"
            :headers="headerAsset"
            :items="filteredDataAsset"
            :search="searchDtAsset"
            :loading="loadingDataTable"
            :loading-text="loadingText"
            :custom-sort="customSort"
            >
                <template v-for="(header, i)  in headerAsset" v-slot:[`header.${header.value}`]="{  }"> 
                    <span @click.stop :key="i">
                        <v-text-field :key="i"
                        v-model="searchObj[header.value]"
                        type="text"
                        prepend-inner-icon="mdi-magnify"
                        outlined
                        dense
                        style="font-weight: 100;"
                        :disabled="isDisable(i)"
                        ></v-text-field>
                        {{ header.text }}
                    </span>
                </template>
                    
               <template v-slot:[`item.status`]="{ item }">
                    <v-chip
                    :color="controlBgColor(item.status)"
                    :class="controlColorFont(item.status)"
                    >
                    {{item.status}}
                    </v-chip>
                </template>

                <template v-slot:[`item.detailsAll`]="{ item }">
                    <v-icon
                    color="primary"
                    dense
                    v-html="item.detailsAll"
                    @click="openFileDoc(item)"
                    >
                    </v-icon>
                </template>

                

            </v-data-table>
        </v-card>

        <!-- Popup Computer Details -->
        <template>
            <v-dialog
            v-model="dialogComputerDisplayDetails"
            persistent
            scrollable
            max-height="10px"
            max-width="650px"
            >
            <v-card>

                <v-card-title class="title-bar-modul">
                <span class="text-h5">Inventory Detail</span><v-icon></v-icon>
                </v-card-title>

                <v-card-text>
                    <a :href="displayImage" target="_blank">
                        <img :src="displayImage? displayImage : nullImage.src" style="display: block;
                            margin-left: auto;
                            margin-right: auto;
                            margin-bottom: 12px;
                            width: 80%;
                            ">
                    </a>
                <v-simple-table class="table-details-info">
                    <template v-slot:default>
                    <tbody>
                        <v-col>
                            <div style="white-space: nowrap;" class="text-subtitle-1 text-bold grey--text">
                                General Information
                            </div>
                        </v-col>
                        
                        <tr
                        v-for="item in displayGeneralDetails"
                        :key="item.name"
                        >
                            <td :class="$vuetify.breakpoint.lgAndUp? 'font-weight-bold dontBreak' : 'font-weight-bold'">
                                <v-icon class="icon-text blackIcon--text" small v-if="$vuetify.breakpoint.lgAndUp">{{ item.icon }}</v-icon>
                                {{ item.key }}
                            </td>
                            
                            <td>{{ item.value }}</td>
                        </tr>
                        <v-col>
                                <div class="text-subtitle-1 text-bold grey--text">
                                   Specification
                                </div>
                            </v-col>
                        <tr
                        v-for="item in displaySpecDetails"
                        :key="item.name"
                        >
                            <td :class="$vuetify.breakpoint.lgAndUp? 'font-weight-bold dontBreak' : 'font-weight-bold'">
                                <v-icon class="icon-text blackIcon--text" small v-if="$vuetify.breakpoint.lgAndUp">{{ item.icon }}</v-icon>
                                {{ item.key }}
                            </td>
                            
                            <td v-if="item.value != 'PENDING' && item.value != 'APPROVE'">{{ item.value }}</td>
                            <td v-if="item.value == 'PENDING'">
                                <v-chip
                                dense
                                color="pending"
                                class="blackk--text text-bold"
                                >
                                {{ item.value }}
                                </v-chip>
                            </td>
                            <td v-if="item.value == 'APPROVE'">
                                <v-chip
                                dense
                                color="success"
                                class="white--text text-bold"
                                >
                                {{ item.value }}
                                </v-chip>
                            </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                class="text-bold"
                color="primary"
                text
                @click="dialogComputerDisplayDetails = false"
                >
                    Close
                </v-btn>
                </v-card-actions>
            
            </v-card>
            </v-dialog>
        </template>

        <!-- Popup Monitor Details -->
        <template>
            <v-dialog
            v-model="dialogMonitorDisplayDetails"
            persistent
            scrollable
            max-height="10px"
            max-width="650px"
            >
            <v-card>

                <v-card-title class="title-bar-modul">
                <span class="text-h5">Inventory Detail</span><v-icon></v-icon>
                </v-card-title>

                <v-card-text>
                    <a :href="displayImage" target="_blank">
                        <img :src="displayImage? displayImage : nullImage.src" style="display: block;
                            margin-left: auto;
                            margin-right: auto;
                            width: 80%;
                            ">
                    </a>
                <v-simple-table class="table-details-info">
                    <template v-slot:default>
                    <tbody>
                        <!-- <v-col>
                            <div style="white-space: nowrap;" class="text-subtitle-1 text-bold grey--text">
                                General Information
                            </div>
                        </v-col> -->
                        
                        <tr
                        v-for="item in displayMonitorGeneralDetails"
                        :key="item.name"
                        >
                            <td :class="$vuetify.breakpoint.lgAndUp? 'font-weight-bold dontBreak' : 'font-weight-bold'">
                                <v-icon class="icon-text blackIcon--text" small v-if="$vuetify.breakpoint.lgAndUp">{{ item.icon }}</v-icon>
                                {{ item.key }}
                            </td>
                            
                            <td>{{ item.value }}</td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                class="text-bold"
                color="primary"
                text
                @click="dialogMonitorDisplayDetails = false"
                >
                    Close
                </v-btn>
                </v-card-actions>
            
            </v-card>
            </v-dialog>
        </template>

        <!-- Popup Accessories Details -->
        <template>
            <v-dialog
            v-model="dialogAccessoriesDisplayDetails"
            persistent
            scrollable
            max-height="10px"
            max-width="650px"
            >
            <v-card>

                <v-card-title class="title-bar-modul">
                <span class="text-h5">Inventory Detail</span><v-icon></v-icon>
                </v-card-title>

                <v-card-text>
                    <a :href="displayImage" target="_blank">
                        <img :src="displayImage? displayImage : nullImage.src" style="display: block;
                            margin-left: auto;
                            margin-right: auto;
                            width: 80%;
                            ">
                    </a>
                <v-simple-table class="table-details-info">
                    <template v-slot:default>
                    <tbody>
                        <!-- <v-col>
                            <div style="white-space: nowrap;" class="text-subtitle-1 text-bold grey--text">
                                General Information
                            </div>
                        </v-col>
                         -->
                        <tr
                        v-for="item in displayAccessoriesGeneralDetails"
                        :key="item.name"
                        >
                            <td :class="$vuetify.breakpoint.lgAndUp? 'font-weight-bold dontBreak' : 'font-weight-bold'">
                                <v-icon class="icon-text blackIcon--text" small v-if="$vuetify.breakpoint.lgAndUp">{{ item.icon }}</v-icon>
                                {{ item.key }}
                            </td>

                            <td>{{ item.value }}</td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                class="text-bold"
                color="primary"
                text
                @click="dialogAccessoriesDisplayDetails = false"
                >
                    Close
                </v-btn>
                </v-card-actions>
            
            </v-card>
            </v-dialog>
        </template>

    </v-container>
</template>

<script>
import axios from 'axios';
export default {
    
    data: () => ({

        loadingDataTable: true,
        loadingText: "", //Data is Loading... Please wait

        searchDtAsset: '',
        headers: [],
        allData: [],
        searchObj: {},
        multiSearch: [],

         // Popup Section
        dialogComputerDisplayDetails: false,
        dialogMonitorDisplayDetails: false,
        dialogAccessoriesDisplayDetails: false,

        nullImage: {
            src: require('@/assets/img/meetingRooms/none-image.jpg')
        },
        displayImage: "",

        headerAsset: [
            {text: "Type", value: "assetname"},
            {text: "Brand", value: "brand"},
            {text: "Model", value: "model"},
            {text: "Serial No.", value: "serial_no"},
            {text: "Tag No.", value: "asset_tag"},
            {text: "Date Purchased", value: "date_purchased"},
            {text: "Status", value: "status"},
            {text: "Detail", value: "detailsAll", sortable: false},
        ],
        bodyAsset: [
            {
                type: "Desktop",  
                brand: "DELL",  
                model: "OPTIPLEX 3050",  
                seriesNum: "D6RCMP2",
                tagNum: "MNJ005DSTP",
                datePurchase: "2019-07-31",  
                status: "Available",  
                detailsAll: "mdi-file-document",  
            },
        ],
        bodyAssetEmp:[],
        displayGeneralDetails: [
            {key: "Type", value: "", icon: "mdi-pin-outline"},    
            {key: "Employee Name", value: "", icon: "mdi-account"},  
            {key: "Brand", value: "", icon: "mdi-watermark"},    
            {key: "Asset Tag", value: "", icon: "mdi-label"}, 
            {key: "Serial Number", value: "", icon: "mdi-numeric"},    
            {key: "Model", value: "", icon: "mdi-globe-model"}, 
            // {key: "Monitor Size", value: "", icon: "mdi-monitor-screenshot"},       
            {key: "Warranty Start Date", value: "", icon: "mdi-calendar-start"},    
            {key: "Warranty End Date", value: "", icon: "mdi-calendar-end"},    
            {key: "Purchased By", value: "", icon: "mdi-account-arrow-right"},
            {key: "Purchased Date", value: "", icon: "mdi-calendar-outline"},    
            {key: "Disposed By", value: "", icon: "mdi-account-alert"},  
            {key: "Registered By", value: "", icon: "mdi-account-details"}, 
            {key: "Remarks", value: "", icon: "mdi-bookmark-check-outline"}, 
            {key: "Status", value: "", icon: "mdi-check-decagram-outline"},
        ],

        displaySpecDetails: [
            {key: "Processor", value: "", icon: "mdi-memory"},    
            {key: "Mac LAN", value: "", icon: "mdi-lan"},    
            {key: "Mac WiFi", value: "", icon: "mdi-wifi"},    
            {key: "RAM", value: "", icon: "mdi-camera-metering-partial"},    
            {key: "Storage", value: "", icon: "mdi-database"},  
        ],

        displayMonitorGeneralDetails: [
            {key: "Type", value: "", icon: "mdi-pin-outline"},    
            {key: "Employee Name", value: "", icon: "mdi-account"},  
            {key: "Brand", value: "", icon: "mdi-watermark"},    
            {key: "Asset Tag", value: "", icon: "mdi-label"}, 
            {key: "Serial Number", value: "", icon: "mdi-numeric"},    
            {key: "Model", value: "", icon: "mdi-globe-model"}, 
            {key: "Monitor Size", value: "", icon: "mdi-monitor-screenshot"},       
            {key: "Warranty Start Date", value: "", icon: "mdi-calendar-start"},    
            {key: "Warranty End Date", value: "", icon: "mdi-calendar-end"},    
            {key: "Purchased By", value: "", icon: "mdi-account-arrow-right"},
            {key: "Purchased Date", value: "", icon: "mdi-calendar-outline"},    
            {key: "Disposed By", value: "", icon: "mdi-account-alert"},  
            {key: "Registered By", value: "", icon: "mdi-account-details"}, 
            {key: "Remarks", value: "", icon: "mdi-bookmark-check-outline"}, 
            {key: "Status", value: "", icon: "mdi-check-decagram-outline"},
        ],

        displayAccessoriesGeneralDetails: [
            {key: "Type", value: "", icon: "mdi-pin-outline"},    
            {key: "Employee Name", value: "", icon: "mdi-account"},  
            {key: "Brand", value: "", icon: "mdi-watermark"},
            {key: "Item", value: "", icon: "mdi-mouse-variant"},
            {key: "Asset Tag", value: "", icon: "mdi-label"}, 
            {key: "Serial Number", value: "", icon: "mdi-numeric"},    
            {key: "Model", value: "", icon: "mdi-globe-model"}, 
            // {key: "Monitor Size", value: "", icon: "mdi-monitor-screenshot"},       
            {key: "Warranty Start Date", value: "", icon: "mdi-calendar-start"},    
            {key: "Warranty End Date", value: "", icon: "mdi-calendar-end"},    
            {key: "Purchased By", value: "", icon: "mdi-account-arrow-right"},
            {key: "Purchased Date", value: "", icon: "mdi-calendar-outline"},    
            {key: "Disposed By", value: "", icon: "mdi-account-alert"},  
            {key: "Registered By", value: "", icon: "mdi-account-details"}, 
            {key: "Remarks", value: "", icon: "mdi-bookmark-check-outline"}, 
            {key: "Status", value: "", icon: "mdi-check-decagram-outline"},
        ],
        dialogDisplayDetails: false,

    }),
    methods: {

        // Data Table Section
        loadDataTable() {

            this.loadingDataTable = true;
            this.loadingText = "Data is Loading... Please wait";

            this.allData = []
           
            axios.get(process.env.VUE_APP_API_URL + 'asset/all', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {
                let data = response.data;          
                this.bodyAsset = data.filter(x=>x.idemp == this.$store.getters.user.idEmp)
                
                for (let i = 0; i < this.bodyAsset.length; i++) {
                    if(data[i].date_purchased == null || data[i].date_purchased == "") {
                        this.bodyAsset[i]["date_purchased"]= "N/A";
                    } else {
                        this.bodyAsset[i]["date_purchased"]=this.convertDateString(data[i].date_purchased);
                    }

                    if(data[i].asset_tag == null || data[i].asset_tag == "") {
                        this.bodyAsset[i]["asset_tag"]= "N/A";
                    } else {
                        this.bodyAsset[i]["asset_tag"]=data[i].asset_tag;
                    }

                    if(data[i].model == null || data[i].model == "") {
                        this.bodyAsset[i]["model"]= "N/A";
                    } else {
                        this.bodyAsset[i]["model"]=data[i].model;
                    }
                    
                    this.bodyAsset[i]["detailsAll"]="mdi-file-document"
                }

                this.allData = this.bodyAsset
                this.headers = this.headerAsset

                this.loadingDataTable = false;
                this.loadingText = "";
            })
            .catch(error => {
                console.log(error);
                this.loadingDataTable = false;
                this.loadingText = "";
            })

        },

        customSort: function(items, index, isDesc) {

            // console.log(items, index, isDesc);

            items.sort((a, b) => {
                if (index[0]=='dtApplied' || index[0]=='startDateTime' || index[0]=='endDateTime') {
                    if (!isDesc[0]) {
                        return new Date(b[index]) - new Date(a[index]);
                    } else {
                        return new Date(a[index]) - new Date(b[index]);
                    }
                }
                else {
                    if(typeof a[index] !== 'undefined'){
                        if (!isDesc[0]) {
                            return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
                        }
                        else {
                            return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
                        }
                    }
                }
            });
            return items;
        },

        controlColorFont(status){
            if (status == "AVAILABLE") {
                return "white--text text-bold" 
            }
            else if(status == "NOT AVAILABLE"){
                return "white--text text-bold" 
            }
        },

        controlBgColor(status){
            if (status == "AVAILABLE") {
                return "green" 
            }
            else if(status == "NOT AVAILABLE"){
                return "red" 
            }
        },

        isDisable(index) {
            // console.log(index);
            if (index == 7) {
                return true;
            }
            else{
                return false;
            }
        },

        openFileDoc(item){
            // console.log("kkkk", item);
            this.displayImage = item.picture
            if(item.assetname=="Laptop" ||item.assetname=="Desktop" ||item.assetname=="Server") {

                for (let i = 0; i < this.displayGeneralDetails.length; i++) {
                    this.displayGeneralDetails[i].value = "";
                }
                
                this.displayGeneralDetails[0].value = item.assetname;
                this.displayGeneralDetails[1].value = item.empname;
                this.displayGeneralDetails[2].value = item.brand;
                this.displayGeneralDetails[3].value = item.asset_tag;
                this.displayGeneralDetails[4].value = item.serial_no;
                this.displayGeneralDetails[5].value = item.model;
                // this.displayGeneralDetails[6].value = item.monitorSize;
                this.displayGeneralDetails[6].value = this.convertDateString(item.start_date_warranty);
                this.displayGeneralDetails[7].value = this.convertDateString(item.end_date_warranty);
                this.displayGeneralDetails[8].value = item.purchaser_name;
                this.displayGeneralDetails[9].value = item.date_purchased;
                this.displayGeneralDetails[10].value = item.disposed_name;
                this.displayGeneralDetails[11].value = item.registrar_name;
                this.displayGeneralDetails[12].value = item.remarks;
                this.displayGeneralDetails[13].value = item.status;

                for (let i = 0; i < this.displaySpecDetails.length; i++) {
                    this.displaySpecDetails[i].value = "";
                }
                
                this.displaySpecDetails[0].value = item.processor;
                this.displaySpecDetails[1].value = item.mac_address_lan;
                this.displaySpecDetails[2].value = item.mac_address_wifi;
                this.displaySpecDetails[3].value = item.ram;
                this.displaySpecDetails[4].value = item.storage;

                this.dialogComputerDisplayDetails = true
            }

            else if(item.assetname=="Monitor") {
                for (let i = 0; i < this.displayMonitorGeneralDetails.length; i++) {
                    this.displayMonitorGeneralDetails[i].value = "";
                }
                
                this.displayMonitorGeneralDetails[0].value = item.assetname;
                this.displayMonitorGeneralDetails[1].value = item.empname;
                this.displayMonitorGeneralDetails[2].value = item.brandAsset;
                this.displayMonitorGeneralDetails[3].value = item.asset_tag;
                this.displayMonitorGeneralDetails[4].value = item.serial_no;
                this.displayMonitorGeneralDetails[5].value = item.model;
                this.displayMonitorGeneralDetails[6].value = item.monitor_size;
                this.displayMonitorGeneralDetails[7].value = this.convertDateString(item.start_date_warranty);
                this.displayMonitorGeneralDetails[8].value = this.convertDateString(item.end_date_warranty);
                this.displayMonitorGeneralDetails[9].value = item.purchaser_name;
                this.displayMonitorGeneralDetails[10].value = item.date_purchased;
                this.displayMonitorGeneralDetails[11].value = item.disposed_name;
                this.displayMonitorGeneralDetails[12].value = item.registrar_name;
                this.displayMonitorGeneralDetails[13].value = item.remarks;
                this.displayMonitorGeneralDetails[14].value = item.status;

                this.dialogMonitorDisplayDetails = true
            }

            else if(item.assetname=="Accessories") {
                for (let i = 0; i < this.displayAccessoriesGeneralDetails.length; i++) {
                    this.displayAccessoriesGeneralDetails[i].value = "";
                }
                
                this.displayAccessoriesGeneralDetails[0].value = item.assetname;
                this.displayAccessoriesGeneralDetails[1].value = item.empname;
                this.displayAccessoriesGeneralDetails[2].value = item.brand;
                this.displayAccessoriesGeneralDetails[3].value = item.item;
                this.displayAccessoriesGeneralDetails[4].value = item.asset_tag;
                this.displayAccessoriesGeneralDetails[5].value = item.serial_no;
                this.displayAccessoriesGeneralDetails[6].value = item.model;
                // this.displayAccessoriesGeneralDetails[6].value = item.monitorSize;
                this.displayAccessoriesGeneralDetails[7].value = this.convertDateString(item.start_date_warranty);
                this.displayAccessoriesGeneralDetails[8].value = this.convertDateString(item.end_date_warranty);
                this.displayAccessoriesGeneralDetails[9].value = item.purchaser_name;
                this.displayAccessoriesGeneralDetails[10].value = item.date_purchased;
                this.displayAccessoriesGeneralDetails[11].value = item.disposed_name;
                this.displayAccessoriesGeneralDetails[12].value = item.registrar_name;
                this.displayAccessoriesGeneralDetails[13].value = item.remarks;
                this.displayAccessoriesGeneralDetails[14].value = item.status;

                this.dialogAccessoriesDisplayDetails = true
            } 
        }

    },
    mounted(){
        this.loadDataTable();
    },
    computed: {
        filteredDataAsset(){
            if (this.searchObj) {
                return this.allData.filter(item => {
                    return Object.entries(this.searchObj).every(([key, value]) => {
                        return (item[key] ||'').toLowerCase().includes(value.toLowerCase())
                    })
                })
            }
            else{
                return this.allData
            } 
        },
    }

}

</script>

<style lang="scss">
@import '~scss/main';



</style>
